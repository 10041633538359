import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";

import { CampaignObjectType } from "@vre-wave/domain";

@Injectable({ providedIn: "root" })
export class NavigationService {
    get title$() {
        return this.#title$.asObservable();
    }
    get hideNav$() {
        return this.#hideNav$.asObservable();
    }

    #title$ = new BehaviorSubject<string>("");
    #hideNav$ = new BehaviorSubject<boolean>(false);
    canClickTitle$ = new BehaviorSubject<boolean>(false);
    showBackButton$ = new BehaviorSubject<boolean>(false);
    lastCaseId$ = new BehaviorSubject<Guid | undefined>(undefined);

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === "primary"),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                this.#title$.next(event.title);
                this.#hideNav$.next(event.hideNav || this.#hideNav$.value);
            });
    }

    setTitle(title: string) {
        setTimeout(() => {
            this.#title$.next(title);
        }, 200);
    }
    clearTitle() {
        this.#title$.next("");
    }

    setLastCase(caseId: Guid) {
        this.lastCaseId$.next(caseId);
    }

    navigateToCampaign(campaignId: Guid, type: CampaignObjectType) {
        switch (type) {
            case CampaignObjectType.Case:
                this.router.navigate(["/campaign-object", "case", "campaigns", campaignId]);
                break;
            case CampaignObjectType.Office:
                this.router.navigate(["/campaign-object", "office", "campaigns", campaignId]);
                break;
        }
    }
}
